import React from 'react';
import SearchDB from './SearchDB';

function App() {
  return (
    <div className="App">
      <SearchDB />
    </div>
  );
}

export default App;
